<template>
  <div class="terms-of-use__wrapper">
    <div class="terms-of-use">
      <div class="terms-of-use__head-wrapper">
        <div class="terms-of-use__head">
          <div class="bold-600 fs-36 mb-3">
            Privacy policy
          </div>
          <div class="bold fs-18">
            Effective date: 15 May 2024
          </div>
        </div>
      </div>

      <div class="terms-of-use__content-wrapper">
        <div class="terms-of-use__content">
          <p>SatoshiEye ("SatoshiEye") has created this Privacy Policy in line with SatoshiEye's commitment to your privacy on SatoshiEye websites and marketing initiatives. This policy is designed in  compliance with the requirements of the Swiss Federal Act on Data Protection (FADP) and the General Data Protection Regulation (GDPR) of the European Union (“Data Protection Laws”). This Privacy Policy applies to the personal information that SatoshiEye collects throughout its website , dissemination and protection practices in accordance with the Data Protection Laws.</p>
          <div class="bold-600 fs-24 mb-2">
            Compliance
          </div>
          <p>By accessing or using SatoshiEye websites and Services, you agree to the terms of this Privacy Policy. If you do not agree with the policy, please refrain from using our websites and Services.</p>
          <p>For visitors from the European Economic Area (EEA), the processing of your personal data is based on the legitimate business interests of SatoshiEye, as permitted under the General Data Protection Regulation (GDPR). However, the GDPR also grants you certain rights to control the extent of processing and the duration of storage of your personal data. These rights are detailed further in the "Your data protection rights" section of this policy.</p>
          <div class="bold-600 fs-24 mb-2">
            Collection of information
          </div>
          <p>SatoshiEye collects information necessary to provide services to its users and to better understand the visitors to its websites and what content is of interest to them. SatoshiEye collects information in the following ways:</p>
          <ul>
            <li>information you affirmatively choose to submit to us (such as your name and email address) for the purpose of accessing various downloads, viewing specific content, subscribing to SatoshiEye newsletters, registering for events and your preferences in receiving marketing from SatoshiEye and its third-party partners and your communication preferences, etc;</li>
            <li>information related to Know Your Customer (KYC) and Anti-Money Laundering (AML) compliance, including blockchain addresses and transaction data.</li>
            <li>information we have obtained about you from publicly available sources such as social media, marketing platforms or on events you attended. You will receive marketing communications from SatoshiEye if you have requested information from SatoshiEye or you or your employer has purchased services from SatoshiEye;</li>
            <li>information SatoshiEye receives from your use of SatoshiEye websites, such as the IP address, browser type, ISP, referring/exit pages, platform type, date/time stamp, number of clicks, domain name and country/state of all visitors to SatoshiEye’s websites;</li>
            <li>location information when you use SatoshiEye’s websites as SatoshiEye may collect and process information about your location. SatoshiEye use various technologies and tools to determine location;</li>
            <li>information that is considered aggregated data such as statistical data which SatoshiEye may use for marketing purposes. Aggregated data may be derived from your personal information but is not considered personal information in law as it does not directly or indirectly reveal your identity.</li>
            <li>SatoshiEye collects technical data from various third parties and public sources, such as analytics providers, advertising networks, and search information providers.</li>
          </ul>
          <div class="bold-600 fs-24 mb-2">
            When you engage with SatoshiEye we collect:
          </div>
          <ul>
            <li>Name and email address and information related to Know Your Customer (KYC) and Anti-Money Laundering (AML) compliance, including blockchain addresses and transaction data.</li>
          </ul>
          <p>Other than as allowed  by the Data Protection Laws, SatoshiEye websites do not collect any special categories of personal information about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). SatoshiEye will not collect any information about criminal convictions and offences.</p>
          <p>SatoshiEye will only use your personal information for the purposes for which it was collected unless it reasonably considers that it needs to use it for another reason that is compatible with the original purpose. If SatoshiEye needs to use your personal information for an unrelated purpose, SatoshiEye will notify you and provide you with the legal basis which allows SatoshiEye to do so or request additional consent if necessary.</p>
          <div class="bold-600 fs-24 mb-2">
            The purposes of collecting the above-mentioned proprietary personal data are:
          </div>
          <p>Any information collected by SatoshiEye will be done in accordance with this Privacy Policy for the purposes of:</p>
          <ul>
            <li>improving business decisions,</li>
            <li>supporting the activities you choose to engage in, and</li>
            <li>providing higher quality solutions and services to SatoshiEye’s customers.</li>
          </ul>
          <p>Additionally, SatoshiEye uses your location information to tailor your experience with SatoshiEye websites. It may also use any non-personal information collected to enhance SatoshiEye websites and your IP address for website diagnostics and administration. The Cookie policy can be found here.</p>
          <p>SatoshiEye will only retain your personal information for as long as necessary to fulfil the purposes it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
          <p>To determine the appropriate retention period for personal information, SatoshiEye considers the necessity, legal obligations, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which SatoshiEye processes your personal information and whether it can achieve those purposes through other means, and the applicable legal requirements.</p>
          <p>As explained above, SatoshiEye will collect and use your personal data for marketing purposes.</p>
          <p>Your data will be stored in our CRM system. We will use your data to inform you about SatoshiEye products and services we think may be of your interest and send you our newsletters. For statistical purposes, we will collect information about your response to our emails and our interaction with you to make decisions on future marketing.</p>
          <div class="bold-600 fs-24 mb-2">
            How long do we keep your personal data?
          </div>
          <p>We retain information for different periods of time depending on the nature of the information and our legal obligations. In general, we will retain your personal information to fulfil the purposes for which it was collected, as necessary to comply with our legitimate interests, business requirements, and legal obligations, resolve disputes, protect our assets, and enforce our agreements. We may also retain cached or archived copies of your personal information for a reasonable period of time in accordance with the applicable Data Protection Laws.</p>
          <div class="bold-600 fs-24 mb-2">
            Our principles relating to the processing of your personal data
          </div>
          <p>We use principles relating to the processing of personal data according to Article 5 of General Data Protection Regulation:<br>
            “(a) processed lawfully, fairly and in a transparent manner in relation to individuals (‘lawfulness, fairness and transparency’);<br>
            (b) collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes (‘purpose limitation’);<br>
            (c) adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed (‘data minimisation’);<br>
            (d) accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay (‘accuracy’);<br>
            (e) kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes subject to the implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals (‘storage limitation’);<br>
            (f) processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures (‘integrity and confidentiality’).”</p>
          <div class="bold-600 fs-24 mb-2">
            Sharing of information
          </div>
          <p>SatoshiEye may disclose your personal information to the following categories of recipients:</p>
          <ul>
            <li>to its group companies, professional advisers, third-party services providers and partners who provide data processing services to SatoshiEye (for example, to support the delivery of, provide functionality on, or help to enhance the security of SatoshiEye websites), or who otherwise process personal information for purposes that are described in Privacy Notices or notified to you when SatoshiEye collects your personal information.</li>
            <li>to any competent law enforcement body, regulatory, government agency, court or other third party where SatoshiEye believes disclosure is necessary (a) as a matter of applicable law or regulation, (b) to exercise, establish or defend its legal rights, or (c) to protect your vital interests or those of any other person.</li>
            <li>to any other person with your consent to the disclosure.</li>
          </ul>
          <p>We may disclose your information to our affiliates or subsidiaries, subcontractors, partners from  EU and non-EU countries, which may help us to better process it. We may disclose some of your data to the service providers, who perform functions on our behalf, such as website management, hosting, technical support, and marketing analytics, only if it is required by the project flow. We’ll make sure that they treat it as protective as we do. In all cases these services have implemented separate privacy policies and procedures, we encourage you to familiarise yourself with those policies. Should you not wish for any of these services to process your data, you need to stop using SatoshiEye websites and services.</p>
          <div class="bold-600 fs-24 mb-2">
            Legal basis for processing
          </div>
          <p>If the information you share with us is protected as personal data in the meaning of  the Data Protection Laws, SatoshiEye’s legal basis for collecting and using the personal information described above will depend on the categories of personal information concerned and the specific context in which SatoshiEye collects it.</p>
          <p>However, SatoshiEye will normally collect personal information from you only (a) where it needs the personal information to perform a contract with you, (b) where the processing is in its legitimate interests and is not overridden by your rights, or (c) where it has your consent to do so. In some cases, SatoshiEye may also have a legal obligation to collect personal information from you.</p>
          <p>If you have questions about or need further information concerning the legal basis on which SatoshiEye collects and uses your personal information, please contact SatoshiEye using the contact details provided under the “Contact Details” heading below and in the right-hand side column.</p>
          <div class="bold-600 fs-24 mb-2">
            International transfers
          </div>
          <p>SatoshiEye processes information on servers in many countries around the world and you consent to your personal information being processed on a server located outside the country or region in which you reside, including outside of the EU and EEA. These countries may have data protection laws that are different to the laws of your country. However, SatoshiEye has taken appropriate safeguards to require that your personal information will remain protected in accordance with this Privacy Policy, including, utilising the European Commission’s Standard Contractual Clauses for transfers of personal information between its group companies, which requires all group companies to protect personal information being processed from the European Economic Area to an equivalent standard to that required under European Union data protection law.</p>
          <div class="bold-600 fs-24 mb-2">
            Information security
          </div>
          <p>SatoshiEye strongly follows the provisions of  both GDPR and Swiss FADP in its information security practices, ensuring robust technical and organisational measures are in place to protect your personal data. The measures we use are designed to provide a level of security appropriate to the risk of processing your personal information. We also take measures to ensure our subcontractors, service providers and other partners that process your personal data on our behalf also have appropriate security measures in place.</p>
          <p>SatoshiEye protects your personal information in the same way that it protects its other confidential information, such as storing it on secure servers that only select SatoshiEye personnel have access to, and by encrypting it to help prevent access by any unauthorised parties. SatoshiEye has put in place procedures to deal with any personal information breach and will notify you and any applicable regulator of a breach when legally required to do so.</p>
          <div class="bold-600 fs-24 mb-2">
            Links
          </div>
          <p>SatoshiEye websites contain links, plug-ins and applications to various third-party websites. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. SatoshiEye has no control over, and is not responsible for the content, privacy policies or reliability of such third-party connections. When you leave SatoshiEye’s website, SatoshiEye encourages you to read the privacy notice and privacy policy of every website you visit.</p>
          <div class="bold-600 fs-24 mb-2">
            Your data protection rights
          </div>
          <p>If your personal information is protected by EU data protection law, you have the following data protection rights which you may be able to exercise by contacting SatoshiEye using the contact details provided under the “Contact Details” heading below and in the right-hand side column:</p>
          <ul>
            <li>If you wish to access, correct, update or request the deletion of your personal information, you can do so at any time by contacting SatoshiEye.</li>
            <li>In addition, you can object to the processing of your personal information, ask SatoshiEye to restrict the processing of your personal information or request portability of your personal information by contacting SatoshiEye.</li>
            <li>You have the right to opt out of marketing communications that SatoshiEye sends you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing emails SatoshiEye sends you or by using the relevant opt-out mechanism for non-email-based marketing.</li>
            <li>Similarly, if SatoshiEye has collected and processed your personal information based on your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing SatoshiEye conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</li>
          </ul>
          <p>If you believe that SatoshiEye has not complied with your data protection rights, you have the right to lodge a complaint with the relevant supervisory authority. For individuals in Switzerland, the relevant authority is:</p>
          <p>Federal Data Protection and Information Commissioner (FDPIC)<br>
            Feldeggweg 1<br>
            CH – 3003 Berne Switzerland<br>
            T +41 (0)58 462 43 95<br>
            <a href="mailto:info@edoeb.admin.ch">info@edoeb.admin.ch</a>
          </p>
          <p>For individuals in the European Economic Area (EEA), you have the right to lodge a complaint with your local data protection authority. You can find the contact details for your national data protection authority here: <a href="https://edpb.europa.eu/about-edpb/board/members_en">https://edpb.europa.eu/about-edpb/board/members_en</a></p>
          <p>Before lodging a complaint, we would appreciate the chance to deal with your concerns, so please contact us first at <a href="mailto:support@satoshieye.com">support@satoshieye.com</a>. Please note that lodging a complaint will not affect any other legal rights or remedies that you have access to under applicable data protection laws.</p>
          <p>You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, SatoshiEye may charge a reasonable fee or refuse to comply with your request if your request is clearly unfounded, repetitive or excessive. SatoshiEye tries to respond to all legitimate requests within one month. Occasionally, it may take longer if your request is particularly complex or you have made a number of requests. In this case, SatoshiEye will notify you and keep you updated.</p>
          <p>*If you wish to access or amend any personal data we hold about you, or to request that we delete any information about you may contact us. When technically feasible, SatoshiEye will transfer your personal data to you or transmit it directly to another controller. At any time, you may also object to the processing of your personal data for direct marketing purposes or ask us to restrict the processing of your personal data. If you believe your right to privacy granted by applicable data protection laws has been infringed upon, please contact us. You also have a right to lodge a complaint with data protection authorities.</p>
          <p>For more information about your rights and how to exercise them, we recommend visiting this resource from the European Commission, which is available in many languages.</p>
          <div class="bold-600 fs-24 mb-2">
            Cookies
          </div>
          <p>You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. If you disable or refuse cookies some parts of SatoshiEye websites may become inaccessible or not function properly. For more information about the cookies that SatoshiEye uses, please see SatoshiEye’s <a href="http://satoshieye.com/cookie-policy">Cookie Policy</a>.</p>
          <div class="bold-600 fs-24 mb-2">
            Changes to the policy
          </div>
          <p>SatoshiEye reserves the right to modify or amend this Privacy Policy at its own discretion from time to time. The changes can be viewed here or elsewhere on SatoshiEye websites. Your continued use of SatoshiEye websites constitutes your acceptance of those changes. This version of the Privacy Policy was published on 20/05/2024.</p>
          <div class="bold-600 fs-24 mb-2">
            Contact details
          </div>
          <p>It is important that the personal information SatoshiEye holds about you is accurate and current. Please keep SatoshiEye informed if your personal information changes during your relationship with SatoshiEye. Additionally, if you have any questions about this Privacy Policy, the practices of SatoshiEye websites or your interaction with SatoshiEye via SatoshiEye websites, please contact:</p>
          <p>SatoshiEye (GLO Services AG)<br>Address: Grafenauweg 8, CH-6300 Zug, Switzerland</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>
