<template>
  <div class="terms-of-use__wrapper">
    <div class="terms-of-use">
      <div class="terms-of-use__head-wrapper">
        <div class="terms-of-use__head">
          <div class="bold-600 fs-36 mb-3">
            Cookie Policy
          </div>
          <div class="bold fs-18">
            Effective date: 15 May 2024
          </div>
        </div>
      </div>

      <div class="terms-of-use__content-wrapper">
        <div class="terms-of-use__content">
          <p class="mb-4">
            Our Website uses cookies. This Cookie Policy explains how we use
            cookies when you visit our website at <span class="accent-text-link">https://satoshieye.com</span>. It also informs you about your rights to control the
            scope of our use of Cookie.
          </p>
          <div class="bold-600 fs-24 mb-3">
            What are cookies?
          </div>
          <p class="mb-4">
            Cookies are small text files placed on your device by the website
            you visit. They serve various purposes, such as enhancing website
            performance, understanding visitor usage, and ensuring security.
            "First-party cookies" are set directly by our Website, while
            "third-party cookies" are set by entities other than our Website and
            may enable features like advertising, interactive content, and
            analytics. These third-party entities might recognize your computer
            both on our Website and elsewhere on the internet.
          </p>
          <div class="bold-600 fs-24 mb-3">
            Types of Cookies we use.
          </div>

          <ul class="mb-4">
            <li class="mb-2">
              Essential or Strictly Necessary Cookies: These are vital for
              navigating our Website and using its features. Without these
              cookies, certain services cannot be provided.
            </li>
            <li class="mb-2">
              Performance Cookies: These collect information about how you
              interact with our Website, helping us optimize and ease
              navigation.
            </li>
            <li class="mb-2">
              Analytical or Functional Cookies: These allow our Website to
              remember your choices and provide a more personalized experience.
            </li>
            <li class="mb-2">
              Third-Party Cookies: Placed by entities other than us, these
              cookies enhance our services or help deliver more relevant
              advertising, subject to their own privacy policies.
            </li>
            <li>
              Analytics Cookies: Services like Google Analytics use these
              cookies to track session times, page preferences, and referrers.
            </li>
          </ul>

          <div class="bold-600 fs-24 mb-3">
            Your choices regarding cookies:
          </div>
          <p class="mb-4">
            You have the right to decide whether to accept or reject cookies.
            You can exercise your cookie rights by setting your preferences in
            the cookie consent manager that can be found in the notification
            banner and on our Website. If you choose to reject cookies, you may
            still use our Website though your access to some functionality and
            areas of our Website may be limited or malfunction. You may also set
            or amend your web browser controls to accept or refuse cookies. As
            the means by which you can refuse cookies through your web browser
            controls vary from browser-to-browser and device, you should visit
            your browser’s help menu for more information.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicy',
}
</script>
